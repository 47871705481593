import React, { useState, useContext, useRef, useEffect } from 'react';
import { Currency, ScrollHorizontal } from './style';
import useMeasure from 'react-use-measure';
import useDraggableScrollContainer from '@hooks/useDraggableScrollContainer';
import { symbolsContext } from '@contexts/symbolsContext';
import ScrollContainer from '@components/ScrollContainerStocks';
import { Suspense } from 'react';
import Spring from '@components/Spring';
import StockItem from '@components/StockItem';

const catCode = [
    { value: 'emtia', label: 'Emtia' },
    { value: 'forex', label: 'Forex' },
    { value: 'indices', label: 'Endeks' },
    { value: 'bist', label: 'Bist' },
    { value: 'equities', label: 'Hisse' },
    { value: 'crypto', label: 'Kripto' },
];

const Trade = ({ onSymbolClick }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [ref, { height }] = useMeasure();
    const { containerRef, isDragging } = useDraggableScrollContainer();
    const [selectedCategory, setSelectedCategory] = useState(catCode[0].value);
    const { symbols, livePrice } = useContext(symbolsContext);

    const tabRefs = useRef([]);  

    useEffect(() => {
        const selectedIndex = catCode.findIndex(cat => cat.value === selectedCategory);
        if (tabRefs.current[selectedIndex] && containerRef.current) {
            const tabElement = tabRefs.current[selectedIndex];
            const containerElement = containerRef.current;

            const containerWidth = containerElement.offsetWidth;
            const tabWidth = tabElement.offsetWidth;
            const tabLeft = tabElement.offsetLeft;

            const scrollPosition = tabLeft - containerWidth / 2 + tabWidth / 2;

            containerElement.scrollTo({
                left: scrollPosition,
                behavior: 'smooth',
            });
        }
    }, [selectedCategory, containerRef]); 

    const handleTabClick = (categoryValue, index) => {
        setSelectedCategory(categoryValue);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = symbols.filter(item =>
        item.catCode.toLowerCase() === selectedCategory &&
        item.symbol.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Spring>
            <div className="widget_container">
                <div className="widget_body card g-0">
                    <ScrollHorizontal
                        className={`scroll-container ${isDragging ? 'isDragging' : ''}`}
                        ref={containerRef}
                        style={{ overflowY: 'hidden', touchAction: 'pan-x' }}
                    >
                        {catCode.map((category, index) => (
                            <Currency
                                ref={el => tabRefs.current[index] = el}
                                className={selectedCategory === category.value ? 'active' : ''}
                                onClick={() => handleTabClick(category.value, index)}
                                key={category.value}
                            >
                                <span className="h4" style={{ color: selectedCategory === category.value ? 'white' : '', padding: '25px' }}>
                                    {category.label}
                                </span>
                            </Currency>
                        ))}
                    </ScrollHorizontal>
                    <input
                        type="text"
                        className="field-input"
                        placeholder="Ara..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <ScrollContainer height={height - 1} bg="widget-bg" ref={ref}>
                        <div className="track card" style={{ width: '100%' }}>
                            {filteredData.map((item, i) => (
                                <a key={item.symbol} onClick={() => onSymbolClick(item.symbol)}>
                                    <StockItem
                                        key={item.symbol}
                                        currency={item.symbol}
                                        ask={livePrice[item.symbol] ? livePrice[item.symbol].ask : 'N/A'}
                                        bid={livePrice[item.symbol] ? livePrice[item.symbol].bid : 'N/A'}
                                        i={item.symbol}
                                    />
                                </a>
                            ))}
                        </div>
                    </ScrollContainer>
                    <Suspense fallback={<div className="suspense-text">Loading...</div>}>
                        {/* Dinamik içerik yüklenebilir */}
                    </Suspense>
                </div>
            </div>
        </Spring>
    );
};

export default Trade;
