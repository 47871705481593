import styled from 'styled-components/macro';
import theme from 'styled-theming';

const ScrollHorizontal = styled.div`
  display: flex;
  gap: 8px;
  margin: 0px;
  padding: 16px 16px;
`;

const Currency = styled.div`
    cursor: pointer;
    min-width: 128px;
    height: 34px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    gap: 8px;
    border: 1px solid ${theme('theme', {
      light: 'var(--border-light)',
      dark: 'var(--border-dark)'
    })};
    transition: border-color var(--transition);

    &--hover:hover {
      border-color: var(--accent);
    }
    border-radius: 7px;
    transition: all var(--transition);
  
  &.active {
    background-color: var(--accent);
    
    .title {

    }
  }
  
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme('theme', {
        light: '#f7f7f7',
        dark: 'var(--header-light)',
    })};
    
    img {
      height: 24px;
      width: auto;
    }
  }
`;

const ChartWrapper = styled.div`
  height: 380px;
  margin: -10px 0 -10px -18px;
`;




export {Currency, ChartWrapper, ScrollHorizontal}