// styling
import styles from './style.module.scss';
import React, { useContext } from 'react';
import moment from 'moment';
// components
import Star from '@ui/Star';
import NumLabel from '@ui/NumLabel';
import CurrencyItem from '@components/CurrencyItem';
import Spring from '@components/Spring';
import { fetchCloseOrder, fetchPartialOrder, fetchCancelOrder } from '@services/apiService';
import { ordersContext } from '@contexts/ordersContext';
import { useAuth } from '@contexts/AuthContext';
import {toast} from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// hooks
import {useDispatch} from 'react-redux';
import {useWindowSize} from 'react-use';

// actions
import {toggleFavorite} from '@features/crypto/cryptoSlice';

const MarketItem = ({item, filter, index, withBorder}) => {
	const { refreshOrders } = useContext(ordersContext);
	const { authToken } = useAuth();
	const navigate = useNavigate();
    const {width} = useWindowSize();
    const dispatch = useDispatch();
    const {id, amount, type, symbol, price, closePrice, lastPrice, marketLimit, takeProfit, stopLoss, profit, swap, margin, createdAt, closeAt} = item;
    const isCompact = width < 568 || (width >= 1440 && width < 1700) ||( width >= 1920 && width < 2000);
    const withIcon = (width >= 414 && width < 768) || (width >= 1024 && width < 1280) || width >= 1440;
	
	const formattedDate = moment(createdAt).format('DD.MM.YYYY HH:mm:ss');
	const formattedClosedDate = moment(closeAt).format('DD.MM.YYYY HH:mm:ss');

	const fixedProfit = Number(profit).toFixed(2);

	const handleCloseOrder = async (id, authToken) => {
		try {
			const response = await fetchCloseOrder(id, authToken);
			if (response.status) {
				await refreshOrders('position');
				await refreshOrders('closed');
				toast.success('Pozisyon Kapatıldı.', { autoClose: 3000 });
			} else {
				toast.error(response.message, {
				  autoClose: 3000
				});
			}
		} catch (error) {
			const errorMessage = error.message || 'Bir hata oluştu.';
			  toast.error(errorMessage, {
				autoClose: 5000
			});
		}
	};

	const handleCancelWaiting = async (id, authToken) => {
		try {
			const response = await fetchCancelOrder(id, authToken);
			if (response.status) {
				await refreshOrders('waiting');
				toast.success('İptal Edildi', { autoClose: 3000 });
			}

		} catch (error) {
			toast.warning('Bir Hata Oluştu', { autoClose: 3000 });
		}
	};
	
    return (
        <Spring type="list" index={index}>
            <div className={`${styles.container} card ${withBorder ? 'bordered' : ''}`}>
                <div className={styles.group} style={{ display: 'flex', width: '100%' }}>
					{filter === 'position' ? (
					<>
					<div className={styles.wrapper}>
						<CurrencyItem icon={null} name={symbol} withIcon={withIcon} />
					</div>
 
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{amount}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3 text-capitalize text-600 text-red">{type}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <NumLabel value={price} />
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{lastPrice}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{stopLoss || "-"}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{takeProfit || "-"}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{fixedProfit}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{swap}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{formattedDate}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
						<button className="btn btn--pill btn--invert" onClick={() => handleCloseOrder(id, authToken)}>Kapat</button>
                    </div>
					</>
					) : filter === 'waiting' ? (
					<>
					<div className={styles.wrapper}>
						<CurrencyItem icon={null} name={symbol} withIcon={withIcon} />
					</div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{amount}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3 text-capitalize text-600 text-red">{type}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <NumLabel value={price} />
                    </div>

                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{takeProfit || "-"}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{stopLoss || "-"}</span>
                    </div>

                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{formattedDate}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
						<button className="btn btn--pill btn--invert" onClick={() => handleCancelWaiting(id, authToken)}>İptal</button>
                    </div>
					</>
					) : filter === 'closed' ? (
					
					<>
					<div className={styles.wrapper}>
						<CurrencyItem icon={null} name={symbol} withIcon={withIcon} />
					</div>
 
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{amount}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3 text-capitalize text-600 text-red">{type}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <NumLabel value={price} />
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{closePrice}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{marketLimit || "-"}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{stopLoss || "-"}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
						<NumLabel value={fixedProfit} />
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{swap || 0}</span>
                    </div>
                    <div className={styles.group_cell} style={{ flex: 1 }}>
                        <span className="h3">{formattedClosedDate}</span>
                    </div>
					</>

					
					) : (
						<>

						</>
					)}
					
					
                </div>
            </div>
        </Spring>
    )
}

export default MarketItem