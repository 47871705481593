import React, { createContext, useState, useEffect, useCallback } from 'react';
import { fetchPositions, fetchWaitingOrders, fetchClosedOrders } from '@services/apiService';
import { useAuth } from '@contexts/AuthContext';
import { useWebSocket } from '@contexts/WebSocketContext';

export const ordersContext = createContext();

export const OrdersProvider = ({ children }) => {
  const { authToken } = useAuth();
  const webSocket = useWebSocket();
  const [positions, setPositions] = useState([]);
  const [waitings, setWaitings] = useState([]);
  const [closed, setClosed] = useState([]);
  const [currentPage, setCurrentPage] = useState({ position: 1, waiting: 1, closed: 1 });
  const [totalPages, setTotalPages] = useState({ position: 1, waiting: 1, closed: 1 });

  const loadOrders = useCallback(async (page = 1, type) => {
    if (!authToken) return;

    try {
      let data;
      switch (type) {
        case 'position':
          data = await fetchPositions(authToken, page);
          if (data && data.data) {
            setPositions(prev => (page === 1 ? data.data : [...prev, ...data.data]));
            setTotalPages(prev => ({ ...prev, position: data.totalPages }));
          }
          break;
        case 'waiting':
          data = await fetchWaitingOrders(authToken, page);
          if (data && data.data) {
            setWaitings(prev => (page === 1 ? data.data : [...prev, ...data.data]));
            setTotalPages(prev => ({ ...prev, waiting: data.totalPages }));
          }
          break;
        case 'closed':
          data = await fetchClosedOrders(authToken, page);
          if (data && data.data) {
            setClosed(prev => (page === 1 ? data.data : [...prev, ...data.data]));
            setTotalPages(prev => ({ ...prev, closed: data.totalPages }));
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Error loading ${type} orders:`, error);
    }
  }, [authToken]);

  useEffect(() => {
    if (!webSocket) return;

    const handlePositionUpdate = (event) => {
      const data = JSON.parse(event.data);
      setPositions(prevPositions =>
        prevPositions.map(position =>
          position.id === data.id
            ? { ...position, profit: data.profit, lastPrice: data.currentPrice }
            : position
        )
      );
    };

    webSocket.addEventListener('message', handlePositionUpdate);
    return () => {
      webSocket.removeEventListener('message', handlePositionUpdate);
    };
  }, [webSocket]);

  useEffect(() => {
    loadOrders(currentPage.position, 'position');
  }, [loadOrders, currentPage.position]);

  useEffect(() => {
    loadOrders(currentPage.waiting, 'waiting');
  }, [loadOrders, currentPage.waiting]);

  useEffect(() => {
    loadOrders(currentPage.closed, 'closed');
  }, [loadOrders, currentPage.closed]);

  const loadMoreOrders = (type) => {
    setCurrentPage(prevPage => ({ ...prevPage, [type]: prevPage[type] + 1 }));
  };

  const refreshOrders = (type) => {
    switch (type) {
      case 'position':
        setPositions([]);
        break;
      case 'waiting':
        setWaitings([]);
        break;
      case 'closed':
        setClosed([]);
        break;
      default:
        break;
    }
    setCurrentPage(prevPage => ({ ...prevPage, [type]: 1 }));
    loadOrders(1, type);
  };

  return (
    <ordersContext.Provider value={{ positions, waitings, closed, totalPages, currentPage, loadMoreOrders, refreshOrders }}>
      {children}
    </ordersContext.Provider>
  );
};
