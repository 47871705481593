import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchSymbols } from '@services/apiService';
import { useAuth } from '@contexts/AuthContext';
import { useWebSocket } from '@contexts/WebSocketContext';

export const symbolsContext = createContext();

export const SymbolsProvider = ({ children }) => {
    const [symbols, setSymbols] = useState([]);
    const [livePrice, setLivePrice] = useState({});

    const { authToken } = useAuth();
    const webSocket = useWebSocket();

    useEffect(() => {
        const loadSymbols = async () => {
            if (authToken) {
                const symbolsData = await fetchSymbols(authToken);
				console.log(symbolsData)
                const initialLivePrice = symbolsData.reduce((acc, symbol) => {
                    acc[symbol.symbol] = {
                        ask: symbol.ask,
                        bid: symbol.bid
                    };
                    return acc;
                }, {});
                setSymbols(symbolsData);
                setLivePrice(initialLivePrice);
            }
        };

        loadSymbols();
    }, [authToken]);

    useEffect(() => {
        if (!webSocket) return;

        const handleMarketUpdate = (event) => {
            const data = JSON.parse(event.data);

            if (data.symbol && data.ask !== undefined && data.bid !== undefined) {
                setLivePrice(prevLivePrice => ({
                    ...prevLivePrice,
                    [data.symbol]: { ask: data.ask, bid: data.bid }
                }));
            }

			if (data.open && data.open.symbol && data.open.isOpen !== undefined) {
				setSymbols(prevSymbols =>
					prevSymbols.map(symbol => 
						symbol.symbol === data.open.symbol 
							? { 
								...symbol, 
								isOpen: data.open.isOpen, 
								oTime: data.open.oTime !== undefined ? data.open.oTime : symbol.oTime 
							} 
							: symbol
					)
				);
			}

        };

        webSocket.addEventListener('message', handleMarketUpdate);

        return () => {
            webSocket.removeEventListener('message', handleMarketUpdate);
        };
    }, [webSocket]);
    return (
        <symbolsContext.Provider value={{ symbols, livePrice }}>
            {children}
        </symbolsContext.Provider>
    );
};

export const useSymbols = () => useContext(symbolsContext);
