import React, { useRef } from 'react';
import { Currency, ScrollHorizontal } from './style';

const catCode = [
    { value: 'emtia', label: 'Emtia' },
    { value: 'forex', label: 'Forex' },
    { value: 'indices', label: 'Endeks' },
    { value: 'bist', label: 'Bist' },
    { value: 'equities', label: 'Hisse' },
    { value: 'crypto', label: 'Kripto' },
];

const StocksTab = ({ selectedCategory, setSelectedCategory, isDragging, containerRef }) => {
    const tabRefs = useRef([]);

    const handleTabClick = (categoryValue, index) => {
        setSelectedCategory(categoryValue);
        if (tabRefs.current[index]) {
            tabRefs.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        }
    };

    return (
        <ScrollHorizontal
            className={`scroll-container ${isDragging ? 'isDragging' : ''}`}
            ref={containerRef}
            style={{ overflowY: 'hidden', touchAction: 'pan-x' }}
        >
            {catCode.map((category, index) => (
                <Currency
                    ref={el => tabRefs.current[index] = el}
                    className={selectedCategory === category.value ? 'active' : ''}
                    onTouchStart={() => handleTabClick(category.value, index)}
                    onClick={() => handleTabClick(category.value, index)}
                    key={category.value}
                >
                    <span className="h4" style={{ color: selectedCategory === category.value ? 'white' : '', padding: '25px'}}>
                        {category.label}
                    </span>
                </Currency>
            ))}
        </ScrollHorizontal>
    );
};

export default StocksTab;
