import React, { useContext, useEffect } from 'react';
import styles from './style.module.scss';
import Spring from '@components/Spring';
import { ordersContext } from '@contexts/ordersContext';

const ClosedDetail = ({ id }) => {
  const { closed = [], refreshOrders } = useContext(ordersContext);

  const order = closed.find(item => item.id === id);

  if (!order || !order.amount) {
    return <div style={{ textAlign: 'center', paddingTop: '30px' }}>Bir Hata Oluştu ve Kapanan Pozisyon Bulunamadı.</div>;
  }

  const closeDate = new Date(order.closeAt);
  const formattedClosedDate = closeDate.toLocaleDateString('tr-TR');
  const formattedClosedTime = closeDate.toLocaleTimeString('tr-TR');

  return (
    <Spring className="widget" type="fade">
      <div className="widget_body card" style={{ paddingBottom: '100px' }}>
        <div className="d-flex g-8">
          <div className={styles.container}>
            <label>Order Id</label>
            <div className="d-flex justify-content-between">
              <span className="h3">{order.id}</span>
            </div>
          </div>
        </div>
        <div className="d-flex g-8">
          <div className={styles.container}>
            <label>Sembol</label>
            <div className="d-flex justify-content-between">
              <span className="h3">{order.symbol}</span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between g-8">
          <div className={styles.container}>
            <label>Tür</label>
            <div className="d-flex justify-content-between">
              <span className="h3">{order.type}</span>
            </div>
          </div>
          <div className={styles.container}>
            <label>Lot Miktarı</label>
            <div className="d-flex justify-content-between">
              <span className="h3">{order.amount}</span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between g-8">
          <div className={styles.container}>
            <label>Açılış Fiyatı</label>
            <div className="d-flex justify-content-between">
              <span className="h3">{order.price}</span>
            </div>
          </div>
          <div className={styles.container}>
            <label>Kapanış Fiyatı</label>
            <div className="d-flex justify-content-between">
              <span className="h3">{order.closePrice}</span>
            </div>
          </div>
        </div>
        <div className="d-flex g-8">
          <div className={styles.container}>
            <label>Kapanış Tarihi</label>
            <div className="d-flex justify-content-between">
              <span className="h3">{formattedClosedDate} {formattedClosedTime}</span>
            </div>
          </div>
        </div>
        <div className="d-flex g-8">
          <div className={styles.container}>
            <label>Kar / Zarar</label>
            <div className="d-flex justify-content-between">
              <span className="h3">{order.profit} USD</span>
            </div>
          </div>
        </div>
      </div>
    </Spring>
  );
};

export default ClosedDetail;
