import styles from './style.module.scss';
import React, { useState, useEffect, useContext } from 'react';
import LabelField from '@components/LabelField';
import EditCheckbox from '@components/m/EditCheckbox';
import RangeSlider from '@components/RangeSlider';
import { useAuth } from '@contexts/AuthContext';
import { ordersContext } from '@contexts/ordersContext';
import {useThemeProvider} from '@contexts/themeContext';

import { fetchCloseOrder, fetchPartialOrder  } from '@services/apiService';
//mobil
import PositionDetailList from '@components/m/PositionDetailList';

import Spring from '@components/Spring';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';

const TradeDetail = (idObject) => {
    const {theme} = useThemeProvider();
	const { authToken } = useAuth();
    const { positions, refreshOrders } = useContext(ordersContext);
    const [loading, setLoading] = useState(true);
    const [position, setPosition] = useState(null);
    const [stopActive, setStopActive] = useState(false); 
    const [takeActive, setTakeActive] = useState(false); 
    const [stopPrice, setStopPrice] = useState(null); 
    const [takePrice, setTakePrice] = useState(null);
    const [livePrice, setLivePrice] = useState(null); 
    const [amount, setAmount] = useState(null); 
	const [activeTab, setActiveTab] = useState('close');
	const { id } = idObject;
	const navigate = useNavigate();



	 useEffect(() => {
		if (positions) {
		  const foundPosition = positions.find(pos => pos.id === id);
		  if (foundPosition) {
			setPosition(foundPosition);
			setAmount(parseFloat(foundPosition.amount) || 0.01);
			setStopActive(foundPosition.stopLoss !== null);
			setTakeActive(foundPosition.takeProfit !== null);

			setStopPrice(stopActive ? foundPosition.stopLoss : foundPosition.lastPrice);
			setTakePrice(takeActive ? foundPosition.takeProfit : foundPosition.lastPrice);

			setLivePrice(foundPosition.lastPrice);
		  }
		  setLoading(false);
		}
	  }, [positions, id, stopActive, takeActive]);
	
    if (loading) {
        return <p>Loading...</p>;
    }

	const minAmount = 0.01;
	const maxAmount = parseFloat(position.amount);

	const increaseAmount = () => {
		setAmount(prevAmount => {
			if (minAmount === maxAmount) return minAmount;
			const value = Math.min(maxAmount, (prevAmount || minAmount) + minAmount);
			if (isNaN(value)) {
				return setAmount(minAmount);
			}
			return Math.min(maxAmount, (prevAmount || minAmount) + minAmount);
		});
	};

	const decreaseAmount = () => {
		setAmount(prevAmount => {

			if (minAmount === maxAmount) return minAmount;
			return Math.max(minAmount, (prevAmount || minAmount) - minAmount);
		});
	};



	const handleChange = (e) => {
		const rawValue = e.target.value.trim();
		const value = rawValue === '' ? minAmount : parseFloat(rawValue);
		if (isNaN(value)) {
			setAmount(parseFloat(minAmount));
		} else {
			setAmount(parseFloat(Math.min(maxAmount, Math.max(minAmount, value))));
		}
	};
	
    const iconPath = `../../assets/symbols/${position.symbol.toLowerCase()}.png`;

	const handleCloseOrder = async () => {
		try {
			const data = await fetchCloseOrder(id, authToken);
			if (data.status) {
				await refreshOrders('closed');
				await refreshOrders('position');
				navigate('/transactions');
				toast.success('Pozisyon Kapatıldı.', {
					autoClose: 3000
				});
			} else {
				console.log('aaaaaaaaaa', data)
				toast.error(data.message, {
				  autoClose: 3000
				});
			}
		} catch (error) {
			const errorMessage = error.message || 'Bir hata oluştu.';
			  toast.error(errorMessage, {
				autoClose: 5000
			});
		}
	};

	const handlePartialClose = async () => {
		try {
			const data = await fetchPartialOrder(position.id, amount, authToken);
			if (data.status) {
				await refreshOrders('closed');
				await refreshOrders('position');
				navigate('/transactions');
			}
		} catch (error) {
			console.error('Error closing the order:', error);
		}
	};


    return (
        <Spring className="widget" type="fade">
            <div className="widget_body card">
                <div className="d-flex flex-column g-5">

					<div className={styles.traderItem}>
						<div className={styles.traderImage}>
							<img src={iconPath} alt=''/>
						</div>
						<div className="d-flex flex-column">
							<h3 style={{fontSize:'19px'}} className="text-overflow">{position.symbol}</h3>
						</div>
						<div className="flex-1">
							<div className="d-flex align-items-center" style={{justifyContent: 'end'}}>
								<span style={{fontSize:'16px'}}>{position.lastPrice}</span>
							</div>
						</div>
					</div>
					<div className={`${styles.tabList} btn-group btn-block base-TabsList-root base-TabsList-horizontal col-2 bordered`}>
						<span 
							className={`${styles.buyTab} trade-tab ${activeTab === 'close' ? 'active' : ''}`} 
							onClick={() => setActiveTab('close')}
							style={{ cursor: 'pointer', background: activeTab === 'close' ? 'var(--accent)' : '#b8b8b800', color: activeTab === 'edit' ? 'var(--text-dark)' : 'var(--widget-light)', textAlign: 'center'}}>
							Pozisyonu Kapat
						</span>		
						<span 
							className={ `${styles.sellTab} trade-tab ${activeTab === 'edit' ? 'active' : ''}`} 
							onClick={() => setActiveTab('edit')}
							style={{ cursor: 'pointer', background: activeTab === 'edit' ? 'var(--accent)' : '#b8b8b800', color: activeTab === 'edit' ? 'var(--widget-light)' : 'var(--text-dark)', textAlign: 'center' }}>
							Pozisyonu Düzenle
						</span>
					</div>

					{activeTab === 'close' && (
					  <>
						<div className="d-flex g-5" style={{ alignItems: 'flex-end' }}>
						  <button 
							className={`btn ${theme === 'light' ? 'btn--invert' : ''}`} 
							onClick={decreaseAmount} 
							style={{ width: '35px' }}>
							-
						  </button>
						  <div style={{ flex: 1, margin: '0 10px' }}>
							<LabelField 
							  id="amount" 
							  placeholder="Min. amount: 0.01" 
							  value={amount} 
							  onChange={handleChange}/>
						  </div>
						  <button 
							className={`btn ${theme === 'light' ? 'btn--invert' : ''}`} 
							onClick={increaseAmount} 
							style={{ width: '35px' }}>
							+
						  </button>
						</div>
						<div style={{paddingTop: '10px'}}>
							<RangeSlider value={amount} onChange={handleChange} min={minAmount} max={maxAmount} step="0.01" />
						</div>
						<div className="d-flex flex-column g-8">
							<PositionDetailList value={amount} title="Maksimum Lot" />
						</div>
							{amount === maxAmount ? (
								<button style={{marginBottom: '15px'}} onClick={handleCloseOrder} className={`${styles.btnBuy} btn ${theme === 'dark' ? 'btn--invert' : ''}`}>
								Pozisyonun Tamamını Kapat
								</button>	
							) : (
								<button style={{marginBottom: '15px'}} onClick={handlePartialClose} className={`${styles.btnBuy} btn ${theme === 'dark' ? 'btn--invert' : ''}`}>
								{amount} Lot Kapat
								</button>	
							)}
						
					  </>
					)}
					
					{activeTab === 'edit' && (
					  <>
						<div style={{padding: '5px'}}>
							<EditCheckbox
								orderId={id}
								stopPrice={stopPrice}
								takePrice={takePrice}
								livePrice={livePrice}
								tab={activeTab}
								stopActive={stopActive}
								takeActive={takeActive}
								id="deneme"	/>							  
						</div>
					  </>
					)}

                </div>

				


            </div>
        </Spring>
    );
}

export default TradeDetail