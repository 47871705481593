// styling
import styles from './style.module.scss';

// components
import MarketItem from '@components/MarketItem';
import SortingControl from '@ui/SortingControl';
import Spring from '@components/Spring';
import WidgetHeader from '@components/WidgetHeader';
import ScrollContainer from '@components/ScrollContainer';

// hooks
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useThemeProvider } from '@contexts/themeContext';
import { useAuth } from '@contexts/AuthContext';
import { ordersContext } from '@contexts/ordersContext';
import { useWindowSize } from 'react-use';
import useMeasure from 'react-use-measure';
import useDraggableScrollContainer from '@hooks/useDraggableScrollContainer';
// actions
import { handleSort } from '@features/crypto/cryptoSlice';

// assets
import iconLight from '@assets/icons/icon-market-light.svg';
import iconDark from '@assets/icons/icon-market-dark.svg';

const StandaloneWrapper = ({ children, height, containerRef }) => {
    return (
        <ScrollContainer height={height} ref={containerRef}>
            <div className="track d-flex flex-column g-8">
                {children}
            </div>
        </ScrollContainer>
    );
}

const BuiltInWrapper = ({ children }) => {
    return (
        <div className="d-flex flex-column g-8">
            {children}
        </div>
    );
}

const FilterTabs = ({ filter, setFilter }) => {
    const tabs = [
        { value: 'position', label: 'Pozisyonlar' },
        { value: 'waiting', label: 'Bekleyenler' },
        { value: 'closed', label: 'Kapanan Pozisyonlar' }
    ];

    return (
        <>
            {tabs.map(tab => (
                <button key={tab.value} className={filter === tab.value ? 'active' : ''} onClick={() => setFilter(tab.value)}>
                    {tab.label}
                </button>
            ))}
        </>
    );
}

const Market = ({ standalone = true }) => {
    const { theme } = useThemeProvider();
    const [filter, setFilter] = useState('position');
    const dispatch = useDispatch();
    const withTitle = useWindowSize().width < 768;
    const [ref, { height }] = useMeasure();
    const { containerRef } = useDraggableScrollContainer();
    const { positions, waitings, closed, totalPages, currentPage, loadMoreOrders, refreshOrders } = useContext(ordersContext);
    const { authToken } = useAuth();

    const Wrapper = standalone ? StandaloneWrapper : BuiltInWrapper;
    const wrapperProps = standalone ? { height, containerRef } : {};

    let filteredData = [];
    switch (filter) {
        case 'position':
            filteredData = positions || [];
            break;
        case 'waiting':
            filteredData = waitings || [];
            break;
        case 'closed':
            filteredData = closed || [];
            break;
        default:
            filteredData = [];
    }

    return (
        <Spring type="fade" className="widget">
            <div className={`d-flex flex-column g-24 ${standalone ? 'pb-16' : 'pb-8'}`} ref={ref}>
                {standalone && (
                    <div className="d-flex flex-column g-16">
                        {withTitle && (
                            <WidgetHeader className="pb-0" title="Market" iconLight={iconLight} iconDark={iconDark} />
                        )}
                        <div className={`tab-list col-3 mb-0 ${theme === 'light' ? 'bordered' : ''}`}>
                            <FilterTabs filter={filter} setFilter={setFilter} />
                        </div>
                    </div>
                )}
            </div>
            <div>
                <div style={{ marginBottom: '15px' }}>
                    <div>
                        {filter === 'position' && (
                            <div className={styles.container}>
                                <div className={styles.group} style={{ display: 'flex', width: '100%' }}>
                                    <div className={styles.wrapper} style={{ flex: 1 }}>Sembol</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Miktar</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Tür</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Alış</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Anlık</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Zar. Dur</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Kar Al</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>K/Z</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Swap</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Tarih</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>İşlem</div>
                                </div>
                            </div>
                        )}
                        {filter === 'waiting' && (
                            <div className={styles.container}>
                                <div className={styles.group} style={{ display: 'flex', width: '100%' }}>
                                    <div className={styles.wrapper} style={{ flex: 1 }}>Sembol</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Miktar</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Tür</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Fiyat</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Zar. Dur</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Kar Al</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Tarih</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>İşlem</div>
                                </div>
                            </div>
                        )}
                        {filter === 'closed' && (
                            <div className={styles.container}>
                                <div className={styles.group} style={{ display: 'flex', width: '100%' }}>
                                    <div className={styles.wrapper} style={{ flex: 1 }}>Sembol</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Miktar</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Tür</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Alış</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Kapanış</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Zar. Dur</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Kar Al</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>K/Z</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Swap</div>
                                    <div className={styles.group_cell} style={{ flex: 1 }}>Tarih</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Wrapper {...wrapperProps}>
                {filteredData.length > 0 ? (
                    <>
                        {filteredData.map((item, index) => (
                            <MarketItem key={item.id || index} item={item} index={index} filter={filter} withBorder={!standalone} />
                        ))}
                        {currentPage[filter] < totalPages[filter] ? (
                            <button onClick={() => loadMoreOrders(filter)} style={{ fontSize: '13px', paddingTop: '14px', paddingBottom: '50px'}}>
                                Daha Fazla
                            </button>
                        ) : (
                            <div style={{ fontSize: '11px', paddingTop: '14px', textAlign: 'center', paddingBottom: '50px', opacity: '0.5'}}>Başka Veri Bulunmamaktadır.</div>
                        )}
                    </>
                ) : (
                    <Spring>
                        <div style={{ textAlign: 'center', paddingTop: '50px' }} className={styles.message}>
                            Herhangi bir veri yok.
                        </div>
                    </Spring>
                )}
            </Wrapper>
        </Spring>
    );
}

export default Market;
